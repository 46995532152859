function setCookie(options){
    const {
      name,
      value = '',
      path = '/',
      duration = 3600,
    } = options;
    
    const durationMs = duration * 1000;
    const expires =
      new Date(Date.now() + durationMs);
  
    document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=${path}`;
}

; (() => {

    const message = document.querySelector('.js-close-message');

    if (!message) {
        return;
    }

    message.addEventListener("click", hideMessage);

    function hideMessage() {

       document.querySelector(".main-message").classList.add("hidden");

        setCookie({
            name: 'hideMessage',
            value: 1
        });

    }

})();