
let mobileMenu = document.querySelector('.mobile-menu');

mobileMenu.addEventListener('click', function(){
    document.querySelector('.site-menu').classList.toggle('mobile-hidden');

    // icon changing 
    let menuIcon = document.querySelector('.mobile-menu i');
    if(menuIcon.classList.contains("fa-bars")) {
        menuIcon.classList.remove("fa-bars");
        menuIcon.classList.add("fa-times");
    }

    else {
        menuIcon.classList.remove("fa-times");
        menuIcon.classList.add("fa-bars");
    }
});

    window.addEventListener('scroll', stickyMenuCheck)
    
    function stickyMenuCheck() {
        const scroll = window.scrollY;
        const mainMenu = document.getElementsByClassName("main-header")[0];
        if (scroll > 100) {
            mainMenu.classList.add("sticky")
        }else {
            mainMenu.classList.remove("sticky");
        }
    }   