 // When the user scrolls the page, execute myFunction 
 window.onscroll = function() {
     if(document.getElementById("myBar")){
        bodyScrolled();
     }  
   
};

function bodyScrolled() {
    //body scrolled in % on the top
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById("myBar").style.width = scrolled + "%";
    /* ./ body scrolled */
}