
function handleSendForms() {

    const sendForms = document.getElementsByClassName("send-form");

    if (!sendForms) {
        return 'No Form to send';
    }

    for (sendForm of sendForms) {
        sendForm.addEventListener("click", handleBookingForm);
    }


    function handleBookingForm(e) {
        const trigger = e.currentTarget;
        const currentForm = trigger.closest("form");
        const inputsWithPatternCheck = currentForm.querySelectorAll('[pattern]');
        const requiredInputs = currentForm.querySelectorAll("[required]:not([type='checkbox'])");
        const requiredCheckboxes = currentForm.querySelectorAll('input[type="checkbox"][required]');
        const maxLength = currentForm.querySelectorAll("[maxlength]");
        let validationFail = false;

        for (requiredCheckbox of requiredCheckboxes) {
            requiredCheckbox.closest(".form-group").querySelector("label").classList.remove("text-danger");
            if (!requiredCheckbox.checked) {
                requiredCheckbox.closest(".form-group").querySelector("label").classList.add("text-danger");
                validationFail = true;
            }
        }

        for (requiredInput of requiredInputs) {

            requiredInput.classList.remove("boder", "border-danger");
            requiredInput.closest(".form-group").querySelector("label").classList.remove("text-danger");

            if (requiredInput.value.length < 1) {
                requiredInput.classList.add("border", "border-danger");
                requiredInput.closest(".form-group").querySelector("label").classList.add("text-danger");
                validationFail = true;
            }
        }

        for (inputPatternElement of inputsWithPatternCheck) {
            inputPatternElement.classList.remove("boder", "border-danger");
            inputPatternElement.closest(".form-group").querySelector("label").classList.remove("text-danger");
            // check if the element with attribute pattern is required or if not empty 
            if (inputPatternElement.hasAttribute("required") || inputPatternElement.value.length > 1) {

                const pattern = inputPatternElement.getAttribute("pattern");
                if (!validatePattern(inputPatternElement.value, pattern)) {
                    inputPatternElement.closest(".form-group").querySelector("label").classList.add("text-danger");
                    inputPatternElement.classList.add("border", "border-danger");
                    validationFail = true;
                }
            }

        }

        if (validationFail) {
            return alert("Имате непопълнени полета задължителни полета (*) или невалидни данни");
        }

        currentForm.submit();
    }

    function validatePattern(patternText, regexText) {
        let regex = new RegExp(regexText);

        // pattern in html should be without first and last symbol for pattern - "/  /" in the pattern
        if (regex.test(patternText)) {
            return true;
        } else {
            return false;
        }
    }

}

handleSendForms();