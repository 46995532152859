function handleBookings() 
{
    const bookingsContainer = document.getElementById("bookings");

    if(!bookingsContainer) {
        return;
    }

    const dayContainers = document.getElementsByClassName('day');
    const dayChangers = document.getElementsByClassName("date-changer"); 
    const closeDay = document.getElementById("closeDay");
    const jsonData = document.getElementById("bookings").getAttribute("data-bookings");
    const allHours = document.getElementsByClassName("hour");
    const bookingForm = document.getElementById("bookingForm");
    const closeBookingsForm = document.getElementById("closeBookingsForm");
    let todayDate = new Date();
    
    for(const dayContainer of dayContainers) { 
        let calendarDate = new Date(dayContainer.getAttribute("rel"));
        if(calendarDate > todayDate) {
            dayContainer.addEventListener("click", showFreeHours);
        }else if(calendarDate == todayDate) {
            dayContainer.addEventListener("click", (() => { alert('За текущата дата Ви съветваме да се обадите на д-р Панайотов. Ако сте на мобилно устройство, може да използвате зелената слушалка в долната част на екрана')
                ;} 
            ));
        }else {
            dayContainer.classList.add("calendar-disabled");
        }        
    }  

    // Set days off 
    const currentMonthDaysOff = JSON.parse(document.querySelector("[data-holidays]").getAttribute("data-holidays"));
    let dayOffDates = [];

    for (let key in currentMonthDaysOff) {
        if (currentMonthDaysOff.hasOwnProperty(key)) {      
          
          const dayOff = document.querySelector(`[rel="${currentMonthDaysOff[key]}"]`);
          const dayHours = dayOff.querySelector('.day-hours');
          dayOff.classList.add("calendar-disabled");
    
          // If not day of and element exist
          if(dayHours) {
            dayHours.innerHTML = "<p class='text-danger'> Няма часове за тази дата </p>";
            dayOff.querySelector('.free-hours').innerHTML = 0;
            dayOff.querySelector('.small').classList.remove("text-success");
            dayOff.querySelector('.small').classList.add("text-danger");
          }     
        }
    }

    for(const dayChanger of dayChangers) {
        dayChanger.addEventListener("click", changeDay)
    }

    for(const hour of allHours) {
        hour.addEventListener("click", bookHour);
    }

    closeDay.addEventListener("click", closeHoursContainer);

    closeBookingsForm.addEventListener("click", closeBookingsFormContainer);

    // functions

    // Set Hours 
    setFreeHours(jsonData); 

    function bookHour(e) {
        const trigger = e.currentTarget;
        const bookingHour = trigger.getAttribute("data-hour");
        const bookingDateUser = trigger.closest(".day-hours-container").querySelector(".current-date").innerHTML;
        const bookingDateDatabase = trigger.closest(".day").getAttribute("rel");

        const closeBookingsForm = document.getElementById("closeBookingsForm");

        closeBookingsForm.classList.remove("hidden");       
        bookingForm.classList.remove("hidden");
        document.getElementById('bookingInfo').setAttribute("placeholder", bookingDateUser +" "+ bookingHour + "ч.");
        document.getElementById("bookingHour").value = bookingHour;
        document.getElementById("bookingDate").value = bookingDateDatabase;
        
    }

    function setFreeHours(data) {

        if(!isJSON(data)) {
            return;
        }       

        const json = JSON.parse(data);
        let adminLoggedIn = document.querySelector("[loggedIn]");
        
        for (let key in json) {
            if (json.hasOwnProperty(key)) {     
                             
                let currentDay = document.querySelector(`[rel="${json[key].booking_date}"]`);
                let currentHour = currentDay.querySelector(`[data-hour="${json[key].booking_hour}"]`);
                let freeHoursContainer = currentDay.querySelector(".free-hours");

                // if(json[key].booking_hour == "15.30" && adminLoggedIn == null) {
                //     continue;
                // }
            

                if(!freeHoursContainer) {
                    continue;
                }                
               

                let freeHoursNumber = parseInt(freeHoursContainer.innerHTML);
                freeHoursNumber --;
                
                if(freeHoursNumber < 0) { 
                    freeHoursNumber = 0;                   
                }

                // If current day doesnt have free hours
                if( freeHoursNumber == 0) {
                    const freeHours = currentDay.querySelector(".small");
                    freeHours.classList.remove("text-success");
                    freeHours.classList.add("text-danger");
                    currentDay.classList.add("calendar-disabled");

                    freeHoursContainer.closest(".day").querySelector(".day-hours").innerHTML = "<p class='text-danger text-center'> Няма свободни часове за тази дата </p>";
                }

                

                freeHoursContainer.innerHTML = freeHoursNumber;
                if(!currentHour) { // if not day of and element exist
                    continue;
                }

                if(freeHoursContainer.innerHTML < 0 ) { // if already booked and then added as holiday etc.
                    freeHoursContainer.innerHTML = 0;
                }

                currentHour.classList.add("calendar-disabled");
                currentHour.removeEventListener("click", bookHour);
                currentHour.addEventListener("click", ()=>{
                    alert("Този час не е свободен. Изберете от тези, които са със син фон.")
                });
                
        

            }               
        }
    }

    // vanillaJS
    function isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }
    

    function showFreeHours(e) {
        const trigger = e.currentTarget;
        trigger.querySelector(".day-hours-container").classList.remove("hidden");
        closeDay.classList.remove("hidden");
    }


    function changeDay(e) {
        const trigger = e.currentTarget;
        let index = parseInt(trigger.getAttribute("data-change"));     
        currentDate = new Date(trigger.closest("td").getAttribute("rel"));       
        
        // show next/prev day 
            // return date and month with 01 instead of 0 
            nextPrevDayNumber = (parseInt(currentDate.getDate())+ index).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
            // month + 1 because months in js start from 0, but in php from 1 
            currentMonthNumber = (parseInt(currentDate.getMonth())+ 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});

            // hide all visible hours containers 
            closeHoursContainer(e);
        
            // show the next/prev day container 
            let nextPrevDayHoursContainer = document.querySelector(`[rel="${currentDate.getFullYear()}-${currentMonthNumber}-${nextPrevDayNumber}"]`);


            if(!nextPrevDayHoursContainer) {
                return alert("Стигнахте до последен/първи ден от месеца");
            }


            if(nextPrevDayHoursContainer.classList.contains("weekend")) {
                index *= 3; 
                nextPrevDayNumber = (parseInt(currentDate.getDate())+ index).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                nextPrevDayHoursContainer = document.querySelector(`[rel="${currentDate.getFullYear()}-${currentMonthNumber}-${nextPrevDayNumber}"]`);
            }

           
            nextPrevDayHoursContainer.querySelector(".day-hours-container").classList.remove("hidden");
            closeDay.classList.remove("hidden");

    }

    function closeHoursContainer(e) {
        e.stopPropagation();
        // console.log(document.querySelector(".day-container:not(.hidden)"))
        document.querySelector(".day-hours-container:not(.hidden)").classList.add("hidden");
        closeDay.classList.add("hidden");

    }

    function closeBookingsFormContainer() {
        bookingForm.classList.add("hidden");
    }  
}

handleBookings();

