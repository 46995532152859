
function submitForm() {
    const formSubmitters = document.getElementsByClassName("submit-form");

    if(!formSubmitters)  {
    return
    }

    for(let submitElement of formSubmitters) {
        submitElement.addEventListener("click", submitThisForm);
    }

    function submitThisForm(e) {
        e.currentTarget.closest("form").submit();
    }

}
submitForm()