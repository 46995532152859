// Премахване на скрола при Modals
function overflow() {
 $('body').css('overflow','hidden');
  $('.topnav').remove("responsive");
   $('.topnav .icon').html("☰");
 }

// Добавяне на скрола при затваряне на Modals
function overflowshow() {
 $('body').css('overflow','scroll');
 
 }

// Премахване на лентата с менюто при избор на меню
let menuList =  document.querySelector(".topnav");
let menuItem = document.querySelectorAll(".topnav span");
    function menuHiding(){
        if(menuList.classList.contains("responsive")){
            menuList.classList.remove("responsive");
              document.querySelector('.topnav .icon').innerHTML = "☰";
        }
    }
    

for(let i = 0; i < menuItem.length; i++){ 
    menuItem[i].addEventListener('click', menuHiding);
}

// /. Премахване на лентата с менюто при избор на меню


// Добавяне Responsive na менюто

function myFunction(element) {
    event.preventDefault();
    let theMenu = document.getElementById("myTopnav");
    theMenu.classList.toggle("responsive");

    if(theMenu.classList.contains("responsive")) { 
        element.innerHTML = "X";
    }

    else { 
        element.innerHTML = "☰";
    }

}


// Set active element in menu onclick 
var topmenuAvctives = document.querySelectorAll(".topnav a");

for (var i=0; i<topmenuAvctives.length; i++) {
    topmenuAvctives[i].addEventListener("click", function(){
        
       for (var j=0; j<topmenuAvctives.length; j++) {
           
           if(topmenuAvctives[j].classList.contains("active")){
               topmenuAvctives[j].classList.remove("active");
           }
       }
       
       this.classList.add("active");
        
    });
}



//Animation js when elements appear
 
$(document).ready(function(){

function visibleHideme(){
        $('.hideme').each(function(){
            
            var top_of_object = $(this).offset().top;
            var top_of_window = $(window).scrollTop();
            var bottom_of_object = $(this).offset().top + $(this).outerHeight();
            var bottom_of_window = $(window).scrollTop() + $(window).height();
                       
            if(top_of_object > top_of_window && bottom_of_window > bottom_of_object - 200){
                $(this).addClass('example'); //you dont need this, remove this line
            }
            else {
                $(this).removeClass('example');
            }
            
        });
    }
    visibleHideme();
    
    $(window).scroll(function(){
        visibleHideme();
    });   
        
});



  
  
  //slider ot 2 diva
  
 function changeTestimonialsSlides(){ 

}     

// Load map
      
function showMap() { 
    $( "#load-map" ).click(function() {
    $( this ).parent().load("/includes/google-maps.html");
    });
    
     $( "#load-map2" ).click(function() {
    $( this ).parent().load("/includes/google-maps.html");
    });
 }
    showMap(); 

