;(() => {
    const settingsOpener = document.querySelector('.settings');

    if (!document.querySelector("#adminPanel") || !settingsOpener) {
        return;
    }


    const editRowButtons = document.getElementsByClassName("edit-row");
    const deleteRowButtons = document.getElementsByClassName("delete-row");
    const closeModals = document.getElementsByClassName("close-modal");
    const thisForm = document.querySelector(".edit-delete-form");


    // Add event listeners 
    for (let editRowButton of editRowButtons) {
        editRowButton.addEventListener("click", editCurrentRow);
    }

    for (let deleteRowButton of deleteRowButtons) {
        deleteRowButton.addEventListener("click", deleteCurrentRow);
    }

    for (let closeModal of closeModals) {
        closeModal.addEventListener("click", handleCloseModal);
    }

    settingsOpener.addEventListener("click", openSettings);



    function editCurrentRow(e) {
        const trigger = e.currentTarget;
        const currentRow = trigger.closest("tr");
        const tdElementsToSet = currentRow.querySelectorAll("td[data-name]");
        const modalDialog = document.querySelector(".modal-form-edit-delete");

        modalDialog.classList.add("display--block");

        for (element of tdElementsToSet) {
            let tdName = element.getAttribute("data-name");         
            document.querySelector(`[name='${tdName}']`).value = element.innerText;
        }

        // Set the date from row
        const date = currentRow.querySelector("[data-code-date]").getAttribute("data-code-date");
        document.querySelector("#bookingDate").value = date;
        
        // Disable input edit from form 
        thisForm.querySelector("[name='delete']").setAttribute("disabled", "");
    }

    function deleteCurrentRow(e) {
        const trigger = e.currentTarget;
        const rowElementsToSet = trigger.closest("tr").querySelectorAll("td[data-name]");
        const modalDialog = document.querySelector(".modal-form-edit-delete");


        modalDialog.classList.add("display--block");

        for (element of rowElementsToSet) {
            let tdName = element.getAttribute("data-name");
            let moodalInput = document.querySelector(`[name='${tdName}']`);
            moodalInput.setAttribute("readonly", "");
            moodalInput.value = element.innerText;
        }

        // Disable input edit from form 
        thisForm.querySelector("[name='update']").setAttribute("disabled", "");
    }

    function handleCloseModal(e) {
        const modalDialog = e.currentTarget.closest(".modal");
        modalDialog.classList.remove("display--block");
        const readOnlys = modalDialog.querySelectorAll("[readonly]");
        const updateDialog = thisForm.querySelector("[name='update']");
        const deleteDialog = thisForm.querySelector("[name='delete']");
        for (let readOnly of readOnlys) {
            readOnly.removeAttribute("readonly");
        }


        if (updateDialog) {
            updateDialog.remove("disabled");
        }

        if (deleteDialog) {
            deleteDialog.remove("disabled");
        }


    }

    function openSettings(e) {
        const settingsModal = document.querySelector(".modal-form-settings");

        settingsModal.classList.add("display--block");
    }


})();