function handleTestimonials(){
    const testimonialsContainer = document.querySelector(".testimonials");

    if(!testimonialsContainer) {
        return;
    }

    const controls = testimonialsContainer.querySelectorAll(".testimonials-control");
    const slides = testimonialsContainer.querySelectorAll(".testimonial-slide");
    

    for(let control of controls) {
        control.addEventListener("click", changeTestimonials)
    }

    function changeTestimonials(e) { 
       
        for (let slide of slides){
            slide.classList.toggle("hidden");
        }
    }
}

handleTestimonials();